<template>
  <div id="professionalsToPay">
    <v-container fluid>
      <v-row>
        <v-col cols="12">
          <div style="float: left" class="mr-3">Professionels à payer</div>
          <v-menu
            ref="menuDate"
            v-model="menuDate"
            :close-on-content-click="false"
            :return-value.sync="date"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="date"
                label="Filtrer par date"
                style="max-width: 250px"
                prepend-icon="mdi-calendar"
                hide-details
                clearable
                outlined
                dense
                readonly
                v-bind="attrs"
                v-on="on"
                @click:clear="clear"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="date"
              type="month"
              color="#37A3C6"
              :max="maxDateAllowed"
              no-title
              scrollable
            >
              <v-spacer></v-spacer>
              <v-btn text color="#37A3C6" @click="menuDate = false">
                Annuler
              </v-btn>
              <v-btn text color="#37A3C6" @click="saveDate"> OK </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
      <v-row class="ml-0">
        <v-col cols="12">
          <v-row :key="synth.id" v-for="synth in paginatedProfessionals.data">
            <v-col cols="12" class="ProfessionalItem--Tile my-1">
              <v-row align="center">
                <v-col cols="auto">
                  {{ synth.user.firstName }} {{ synth.user.lastName }}
                </v-col>
                <v-col cols="auto">
                  <v-icon color="#37A3C6">mdi-briefcase-outline</v-icon>
                  <span class="subtitle-1"> {{ occupation(synth) }}</span>
                </v-col>
                <v-col cols="auto">
                  <v-icon color="#37A3C6">mdi-email-outline</v-icon>
                  <span class="subtitle-1"> {{ synth.user.email }}</span>
                </v-col>
                <v-col cols="auto">
                  IBAN: {{ synth.user.bankInfos.iban }} / BIC:
                  {{ synth.user.bankInfos.bic }} / Titulaire:
                  {{ synth.user.bankInfos.titulaire }}
                </v-col>
                <v-col>
                  <v-row justify="end" class="pr-6"> {{ synth.toPay }}€ </v-row>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12">
          <v-row justify="end">
            <v-pagination
              v-model="page"
              :length="paginatedProfessionals.total_pages"
            ></v-pagination>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { paginator } from "@/utils/functions";
import Occupation from "../enums/Occupation.enum";

export default {
  name: "ProfessionalsToPay",
  data() {
    return {
      date: undefined,
      menuDate: undefined,
      page: 1,
      professionals: [],
    };
  },
  async created() {
    this.professionals = await this.$store.dispatch(
      "professionals/fetchProfessionalsToPay",
      this.date
    );
  },
  computed: {
    maxDateAllowed() {
      const dateTimeNow = new Date();
      return `${dateTimeNow.getFullYear()}-${dateTimeNow.getMonth() + 1}`;
    },
    paginatedProfessionals() {
      return paginator(this.professionals, this.page, 10);
    },
  },
  methods: {
    async saveDate() {
      this.professionals = await this.$store.dispatch(
        "professionals/fetchProfessionalsToPay",
        this.date + "-01"
      );
      this.$refs.menuDate.save(this.date);
    },
    async clear() {
      this.professionals = await this.$store.dispatch("professionals/fetchProfessionalsToPay");
    },
    occupation(professional) {
      switch (professional.user.enterprise?.occupation) {
        case Occupation.MANDATAIRE_JUDICIAIRE:
          return "Mandataire judiciaire";
        case Occupation.AVOCAT:
          return "Avocat";
        case Occupation.ADMINISTRATEUR_JUDICIARE:
          return "Administrateur judiciaire";
        case Occupation.EXPERT_COMPTABLE:
          return "Expert comptable";
        default:
          return "Profession non renseignée";
      }
    },
  },
};
</script>
